module.exports = [
  {
    text: 'How you doin’?',
    actor: 'Joey'
  },
  {
    text: 'Seven! Seven seven seven seven!',
    actor: 'Monica'
  },
  {
    text: 'You’re over me? When you were … under me?',
    actor: 'Ross'
  },
  {
    text: 'WE WERE ON A BREAK!',
    actor: 'Ross'
  },
  {
    text: 'Sorry I’m late, but I left late.',
    actor: 'Rachel'
  },
  {
    text:
      'Just so you know, it’s not that common, it doesn’t happen to every guy, and it is a big deal!',
    actor: 'Rachel'
  },
  {
    text: 'She’s your lobster.',
    actor: 'Phoebe'
  },
  {
    text: 'Tell him to email me at www-dot-haha-not-so-much-dot-com!',
    actor: 'Chandler'
  },
  {
    text: 'Guys can fake it? Unbelievable! The one thing that’s ours!',
    actor: 'Monica'
  },
  {
    text:
      'If you’re going to call me names, I would prefer Ross, the Divorce Force. It’s just cooler',
    actor: 'Ross'
  },
  {
    text: 'What’s not to like? Custard – good. Jam – good. Meat – good!',
    actor: 'Joey'
  },
  {
    text:
      'I’m not so good with the advice. Can I interest you in a sarcastic comment?',
    actor: 'Chandler'
  },
  {
    text:
      'Oh look, ugly naked guy is decorating his Christmas tree! Wow, you should see the size of his Christmas balls!',
    actor: 'Phoebe'
  },
  {
    text:
      'I can’t believe my Dad saw us having sex! He didn’t make it to one of my piano recitals, but this he sees',
    actor: 'Monica'
  }
];
